import clsx from 'clsx';

import { InfoBox, Text } from '@usecyan/ui-kit';

import { AutoRepayStatuses } from '@/utils/types/positions';

export const AutoRepaySelector = ({
  loading,
  autoRepayStatus,
  setAutoRepayStatus,
}: {
  loading: boolean;
  autoRepayStatus: AutoRepayStatuses;
  setAutoRepayStatus: (status: AutoRepayStatuses) => void;
}) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="plan-card flex flex-col gap-1.5">
        <Text size="sm" weight="semibold">
          Auto-repayments
        </Text>
        {
          <div
            className={clsx(
              'flex flex-row justify-between bg-white dark:bg-black',
              loading && 'skeletion h-[31px]',
              'rounded-lg p-1'
            )}
          >
            <div
              className={clsx(
                'plan-switch-box',
                AutoRepayStatuses.disabled === autoRepayStatus ? 'bg-cyan-solid' : 'bg-transparent'
              )}
              onClick={() => {
                setAutoRepayStatus(AutoRepayStatuses.disabled);
              }}
            >
              <Text
                size="xs"
                wrap="nowrap"
                weight="semibold"
                lineHeight="tight"
                color={AutoRepayStatuses.disabled === autoRepayStatus ? 'black' : 'secondary'}
              >
                Turn off
              </Text>
            </div>
            <div
              className={clsx(
                'plan-switch-box',
                AutoRepayStatuses.from_main_wallet === autoRepayStatus ? 'bg-cyan-solid' : 'bg-transparent'
              )}
              onClick={() => {
                setAutoRepayStatus(AutoRepayStatuses.from_main_wallet);
              }}
            >
              <Text
                size="xs"
                wrap="nowrap"
                weight="semibold"
                lineHeight="tight"
                color={AutoRepayStatuses.from_main_wallet === autoRepayStatus ? 'black' : 'secondary'}
              >
                Main wallet
              </Text>
            </div>
            <div
              className={clsx(
                'plan-switch-box',
                AutoRepayStatuses.from_cyan_wallet === autoRepayStatus ? 'bg-cyan-solid' : 'bg-transparent'
              )}
              onClick={() => {
                setAutoRepayStatus(AutoRepayStatuses.from_cyan_wallet);
              }}
            >
              <Text
                size="xs"
                wrap="nowrap"
                weight="semibold"
                lineHeight="tight"
                color={AutoRepayStatuses.from_cyan_wallet === autoRepayStatus ? 'black' : 'secondary'}
              >
                Cyan wallet
              </Text>
            </div>
          </div>
        }
      </div>
      {autoRepayStatus !== AutoRepayStatuses.disabled && (
        <InfoBox
          variant="warning"
          name="Auto-repayment selected"
          message={`${autoRepayStatus == AutoRepayStatuses.from_cyan_wallet ? 'WETH ' : 'ETH '} payments will be automatically deducted
        from your ${autoRepayStatus == AutoRepayStatuses.from_main_wallet ? 'Main ' : 'Cyan '}Wallet. Please fund your 
        ${autoRepayStatus == AutoRepayStatuses.from_main_wallet ? 'Main ' : 'Cyan '}
        Wallet with sufficient ${autoRepayStatus == AutoRepayStatuses.from_main_wallet ? 'WETH ' : 'ETH '} by the payment
        due date. Notices will be sent on failed attempts at retrieving funds automatically.`}
        />
      )}
    </div>
  );
};
