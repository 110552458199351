import { Account } from 'viem';
import { signMessage } from 'wagmi/actions';

import { config } from '@/config/wagmi';

export const getSignInSignature = async (account: Account): Promise<string | undefined> => {
  // WARN: Do not touch `message` unless you know what you're doing!

  const message = `
    Welcome to Cyan!
    
    Click to sign in and accept the Cyan Terms of Service: https://docs.usecyan.com/docs/terms-of-service
    
    This request will not trigger a blockchain transaction or cost any gas fees.
    
    Wallet address: ${account.address}
    `;
  return await signMessage(config, {
    message,
    account,
  });
};
