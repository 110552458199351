import { WaitForTransactionReceiptErrorType, waitForTransactionReceipt } from 'wagmi/actions';

import { config } from '@/config/wagmi';
import { IError } from '@/utils/types/error';

export const txnWaiterWithErrorHandler = async (txn: string, onError?: (err: IError) => void) => {
  try {
    await waitForTransactionReceipt(config, {
      hash: txn as `0x${string}`,
    });
  } catch (error: unknown) {
    if (onError) onError(error as WaitForTransactionReceiptErrorType);
    throw error;
  }
};
