import { useEffect, useMemo, useState } from 'react';
import { Address } from 'viem';
import { useAccount, useChainId, useSendTransaction } from 'wagmi';

import { getChainInfo } from '@usecyan/core-kit';

import { NftTransferModal } from '@/components/modals/nft-transfer-modal';
import { useCyanWallet } from '@/providers/cyan-wallet';
import { useModalStore } from '@/stores';
import { IError } from '@/utils/types/error';
import { IUserNft } from '@/utils/types/nft';

import { buildNftTransferFnData } from '../nft-actions';
import { txnWaiterWithErrorHandler } from '../utils';

export enum Steps {
  transferring,
  done,
}

export const getSteps = ({ txnHash, chainId }: { txnHash?: string; chainId: number }) => {
  return [
    {
      key: Steps.transferring,
      title: 'Transferring NFTs',
      description: 'A small amount of gas is required to transfer',
    },
    {
      key: Steps.done,
      title: 'NFT transfer completed',
      description: 'You have successfully transferred NFTs',
      finalTxn: txnHash
        ? {
            url: getChainInfo(chainId).blockExplorer.url + '/tx/' + txnHash,
            explorerName: getChainInfo(chainId).blockExplorer.name,
          }
        : undefined,
    },
  ];
};

export const useUserNftTransfer = ({
  receiverWallet,
  nfts,
}: {
  receiverWallet: Address;
  nfts: Array<
    IUserNft & {
      transferQuantity: number;
    }
  >;
}) => {
  const { openModal } = useModalStore();
  const { cyanWallet } = useCyanWallet();
  const { address } = useAccount();
  const { sendTransactionAsync } = useSendTransaction();
  const chainId = useChainId();

  const [currentStep, setCurrentStep] = useState(Steps.transferring);
  const [txnHash, setTxnHash] = useState<string | undefined>();

  const openTransferModalWithError = (error: IError) => {
    openModal({
      title: nfts.length > 1 ? 'Transfer NFTs' : 'Transfer NFT',
      children: <NftTransferModal nfts={nfts} error={error} />,
      fullWidth: true,
    });
  };

  const transferWithCyanWallet = async (
    cyanWalletNfts: Array<
      IUserNft & {
        transferQuantity: number;
      }
    >
  ) => {
    if (!cyanWallet) return;
    if (cyanWalletNfts.length === 0) return;
    const transferDatas = cyanWalletNfts.map(nft => {
      const encodedTransferData = buildNftTransferFnData({
        ...nft,
        from: cyanWallet.address,
        to: receiverWallet,
        transferQuantity: nft.transferQuantity,
      });
      return {
        to: nft.collection.address,
        data: encodedTransferData,
        value: 0n,
      };
    });
    let txnHash;
    if (transferDatas.length > 1) {
      txnHash = await cyanWallet.executeBatch(transferDatas, openTransferModalWithError);
    } else if (transferDatas.length === 1) {
      txnHash = await cyanWallet.execute(transferDatas[0], openTransferModalWithError);
    }
    await txnWaiterWithErrorHandler(txnHash, openTransferModalWithError);
    setCurrentStep(Steps.done);
    setTxnHash(txnHash);
  };

  const transferWithMainWallet = async (
    mainWalletNfts: Array<
      IUserNft & {
        transferQuantity: number;
      }
    >
  ) => {
    if (!address) return;
    const nft = mainWalletNfts[0];
    const encodedTransferData = buildNftTransferFnData({
      ...nft,
      from: address,
      to: receiverWallet,
      transferQuantity: nft.transferQuantity,
    });
    const transferTxn = await sendTransactionAsync(
      {
        to: nft.collection.address,
        data: encodedTransferData,
        value: 0n,
      },
      {
        onError: openTransferModalWithError,
      }
    );
    await txnWaiterWithErrorHandler(transferTxn, openTransferModalWithError);
    setCurrentStep(Steps.done);
    setTxnHash(transferTxn);
  };

  const startTransfer = async () => {
    const mainWalletNfts = nfts.filter(nft => !nft.isCyanWalletAsset);
    const cyanWalletNfts = nfts.filter(nft => nft.isCyanWalletAsset);
    if (mainWalletNfts.length === nfts.length && nfts.length > 1) {
      openTransferModalWithError({
        name: 'NFT Transfer Error',
        message: 'You can only transfer multiple NFTs with Cyan Wallet',
      });
      return;
    }
    if (mainWalletNfts.length === 1) {
      await transferWithMainWallet(mainWalletNfts);
    }
    if (cyanWalletNfts.length > 0) {
      await transferWithCyanWallet(cyanWalletNfts);
    }
  };

  useEffect(() => {
    startTransfer();
  }, []);

  const steps = useMemo(() => getSteps({ txnHash, chainId }), [txnHash]);

  return {
    currentStep,
    steps,
  };
};
