import { Address, encodeFunctionData, erc721Abi } from 'viem';
import { readContracts } from 'wagmi/actions';

import { CryptopunkAbi, Erc1155Abi } from '@usecyan/contract-kit';
import { isSameAddress, shortenAddress, shortenName } from '@usecyan/core-kit';

import { config } from '@/config/wagmi';
import { IError } from '@/utils/types/error';
import { INft, NftType } from '@/utils/types/nft';

type INftTypeCheck =
  | { isErc721: true; isErc1155: false; isCryptoPunk: false }
  | { isErc721: false; isErc1155: true; isCryptoPunk: false }
  | { isErc721: false; isErc1155: false; isCryptoPunk: true };

const checkNftType = (nft: INft): INftTypeCheck => {
  switch (nft.metadata.type) {
    case NftType.erc721:
      return {
        isErc721: true,
        isErc1155: false,
        isCryptoPunk: false,
      };
    case NftType.erc1155:
      return {
        isErc721: false,
        isErc1155: true,
        isCryptoPunk: false,
      };
    case NftType.crypto_punk:
      return {
        isErc721: false,
        isErc1155: false,
        isCryptoPunk: true,
      };
    default:
      throw new Error('Invalid NFT type');
  }
};

export const checkOwners = async ({
  nfts,
  owner,
  onError,
}: {
  nfts: INft[];
  owner: Address;
  onError?: (error: IError) => void;
}) => {
  const ownerCheckData = nfts.map(nft => {
    const { isErc721, isErc1155, isCryptoPunk } = checkNftType(nft);
    if (isErc721) {
      return {
        abi: erc721Abi,
        address: nft.collection.address,
        functionName: 'ownerOf',
        args: [BigInt(nft.metadata.tokenId)],
      };
    }

    if (isErc1155) {
      return {
        abi: Erc1155Abi,
        address: nft.collection.address,
        functionName: 'balanceOf',
        args: [owner, BigInt(nft.metadata.tokenId)],
      };
    }

    if (isCryptoPunk) {
      return {
        abi: CryptopunkAbi,
        address: nft.collection.address,
        functionName: 'punkIndexToAddress',
        args: [BigInt(nft.metadata.tokenId)],
      };
    }
    // This return is unreachable, but TypeScript requires it
    throw new Error('Unexpected NFT type');
  });

  const ownerCheckResults = await readContracts(config, {
    contracts: ownerCheckData,
  });

  for (const [index, data] of ownerCheckResults.entries()) {
    if (data.status !== 'success') {
      if (onError) {
        onError({
          name: 'NFT Ownership Error',
          message: `Failed to check owner of NFT at index ${index}`,
        });
      }
      return false;
    }
    const { isErc721, isErc1155, isCryptoPunk } = checkNftType(nfts[index]);
    if (
      (isErc721 && !isSameAddress(data.result as Address, owner)) ||
      (isErc1155 && BigInt(data.result) === 0n) ||
      (isCryptoPunk && !isSameAddress(data.result as Address, owner))
    ) {
      if (onError) {
        onError({
          name: 'NFT Ownership Error',
          message: `${shortenName(nfts[index].collection.name)}:${nfts[index].metadata.tokenId} is not owned by ${shortenAddress(owner)}.`,
        });
      }
      return false;
    }
  }
  return true;
};

export const filterApprovalRequiredNfts = async ({
  nfts,
  owner,
  spender,
  onError,
  isApprovedForAll,
}: {
  nfts: INft[];
  spender: Address;
  owner: Address;
  onError?: (error: IError) => void;
  isApprovedForAll?: boolean;
}) => {
  const approvalCheckData = nfts.map(nft => {
    const { isErc721, isErc1155, isCryptoPunk } = checkNftType(nft);

    if (isErc721) {
      if (isApprovedForAll) {
        return {
          abi: erc721Abi,
          address: nft.collection.address,
          functionName: 'isApprovedForAll',
          args: [owner, spender],
        };
      } else {
        return {
          abi: erc721Abi,
          address: nft.collection.address,
          functionName: 'getApproved',
          args: [BigInt(nft.metadata.tokenId)],
        };
      }
    }

    if (isErc1155) {
      return {
        abi: Erc1155Abi,
        address: nft.collection.address,
        functionName: 'isApprovedForAll',
        args: [owner, spender],
      };
    }

    if (isCryptoPunk) {
      return {
        abi: CryptopunkAbi,
        address: nft.collection.address,
        functionName: 'punkIndexToApprovedAddress',
        args: [BigInt(nft.metadata.tokenId)],
      };
    }

    // This return is unreachable, but TypeScript requires it
    throw new Error('Unexpected NFT type');
  });

  const approvalCheckResults = await readContracts(config, {
    contracts: approvalCheckData,
  });

  const approvalRequiredNfts: INft[] = [];
  for (const [index, data] of approvalCheckResults.entries()) {
    const { isErc721, isErc1155, isCryptoPunk } = checkNftType(nfts[index]);
    if (data.status !== 'success') {
      if (onError) {
        onError({
          name: 'NFT Approval Error',
          message: `Failed to check approval of NFT at index ${index}`,
        });
      }
      return [];
    }

    if (isErc721) {
      if (isApprovedForAll && !data.result) {
        approvalRequiredNfts.push(nfts[index]);
      } else if (!isApprovedForAll && !isSameAddress(data.result as Address, spender)) {
        approvalRequiredNfts.push(nfts[index]);
      }
    }

    if (isErc1155 && !data.result) {
      approvalRequiredNfts.push(nfts[index]);
    }

    if (isCryptoPunk && !isSameAddress(data.result as Address, spender)) {
      approvalRequiredNfts.push(nfts[index]);
    }
  }
  return approvalRequiredNfts;
};

export const buildNftApproveFnData = ({
  nft,
  spender,
  isApprovedForAll,
}: {
  nft: INft;
  spender: Address;
  isApprovedForAll?: boolean;
}) => {
  const { isErc721, isErc1155, isCryptoPunk } = checkNftType(nft);

  if (isErc721) {
    if (isApprovedForAll) {
      return encodeFunctionData({
        abi: erc721Abi,
        functionName: 'setApprovalForAll',
        args: [spender, true],
      });
    }
    return encodeFunctionData({
      abi: erc721Abi,
      functionName: 'approve',
      args: [spender, BigInt(nft.metadata.tokenId)],
    });
  }

  if (isErc1155) {
    return encodeFunctionData({
      abi: Erc1155Abi,
      functionName: 'setApprovalForAll',
      args: [spender, true],
    });
  }

  if (isCryptoPunk) {
    return encodeFunctionData({
      abi: CryptopunkAbi,
      functionName: 'offerPunkForSaleToAddress',
      args: [BigInt(nft.metadata.tokenId), 0n, spender],
    });
  }
  // This return is unreachable, but TypeScript requires it
  throw new Error('Unexpected NFT type');
};

export const buildNftTransferFnData = (
  nft: INft & {
    from: Address;
    to: Address;
    transferQuantity: number;
  }
) => {
  switch (nft.metadata.type) {
    case NftType.erc721:
      return encodeFunctionData({
        functionName: 'safeTransferFrom',
        abi: erc721Abi,
        args: [nft.from, nft.to, BigInt(nft.metadata.tokenId)],
      });
    case NftType.erc1155:
      return encodeFunctionData({
        functionName: 'safeTransferFrom',
        abi: Erc1155Abi,
        args: [nft.from, nft.to, BigInt(nft.metadata.tokenId), BigInt(nft.transferQuantity), '0x'],
      });
    case NftType.crypto_punk:
      return encodeFunctionData({
        functionName: 'transferPunk',
        abi: CryptopunkAbi,
        args: [nft.to, BigInt(nft.metadata.tokenId)],
      });
    default:
      throw new Error('Invalid NFT type');
  }
};

export const buildErc1155BatchTransferFnData = ({
  receiver,
  owner,
  nfts,
}: {
  receiver: Address;
  owner: Address;
  nfts: Array<{ address: Address; tokenId: string; amount: number }>;
}) => {
  return encodeFunctionData({
    functionName: 'safeBatchTransferFrom',
    abi: Erc1155Abi,
    args: [owner, receiver, nfts.map(nft => BigInt(nft.tokenId)), nfts.map(nft => BigInt(nft.amount)), '0x'],
  });
};
