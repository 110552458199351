import { NumericFormat } from 'react-number-format';
import { isAddress } from 'viem';
import { useAccount } from 'wagmi';

import { shortenAddress } from '@usecyan/core-kit';
import { Button, InfoBox, Input, Text } from '@usecyan/ui-kit';

import { useCyanWallet } from '@/providers/cyan-wallet';
import { IError } from '@/utils/types/error';
import { IUserNft, NftType } from '@/utils/types/nft';

export const NftTransferForm = ({
  nfts,
  error,
  quantity,
  setQuantity,
  walletAddress,
  setWalletAddress,
  showTransferStepper,
}: {
  nfts: IUserNft[];
  error?: IError;
  quantity: string;
  setQuantity: (value: string) => void;
  walletAddress: string;
  setWalletAddress: (value: string) => void;
  showTransferStepper: () => void;
}) => {
  const { cyanWallet } = useCyanWallet();
  const { address } = useAccount();

  return (
    <div className="col-span-6 h-full w-full">
      <div className="flex h-full flex-col justify-between gap-y-3">
        <div className="flex w-full flex-col gap-y-3">
          {nfts.length === 1 && nfts[0].metadata.type === NftType.erc1155 && (
            <div className="flex flex-col gap-y-1">
              <Text size="sm">Quantity:</Text>
              <NumericFormat
                valueIsNumericString
                value={quantity}
                onValueChange={values => {
                  setQuantity(values.value);
                }}
                placeholder="0"
                max={nfts[0].metadata.amount}
                customInput={Input}
              >
                <Input.Btn
                  className="cursor-pointer px-5 py-2"
                  onClick={() => {
                    setQuantity(nfts[0].metadata.amount.toString());
                  }}
                >
                  <Text size="xs" color="secondary" wrap="nowrap">
                    Max: {nfts[0].metadata.amount}
                  </Text>
                </Input.Btn>
              </NumericFormat>
            </div>
          )}
          <div className="flex w-full flex-col gap-y-2">
            <Text size="sm">Transfer to NFT address:</Text>
            <Input
              placeholder="0x1abcd..."
              value={walletAddress}
              onChange={e => {
                setWalletAddress(e.target.value);
              }}
            >
              {nfts.length === 1 && !nfts[0].isCyanWalletAsset && cyanWallet && (
                <Input.Btn
                  onClick={() => {
                    setWalletAddress(cyanWallet.address);
                  }}
                >
                  <Text size="xs" color="secondary" wrap="nowrap">
                    Cyan Wallet
                  </Text>
                </Input.Btn>
              )}
              {nfts.every(n => n.isCyanWalletAsset) && address && (
                <Input.Btn
                  onClick={() => {
                    setWalletAddress(address);
                  }}
                >
                  <Text size="xs" color="secondary" wrap="nowrap">
                    {shortenAddress(address)}
                  </Text>
                </Input.Btn>
              )}
            </Input>
          </div>
          {nfts.length > 1 && nfts.some(nft => !nft.isCyanWalletAsset) && (
            <InfoBox
              variant="warning"
              message=" Only NFTs in your Cyan Wallet can be transferred in batches. The items in your Main Wallet will not be
                   transferred."
              name="Important Notice"
            />
          )}
          {error && <InfoBox variant="error" message={error.message} name={error.name} />}
        </div>
        <Button radius="sm" onClick={showTransferStepper} disabled={walletAddress === '' || !isAddress(walletAddress)}>
          Transfer
        </Button>
      </div>
    </div>
  );
};
