import { useEffect, useMemo, useRef, useState } from 'react';
import { TbCheck, TbCopy } from 'react-icons/tb';
import { Address } from 'viem';

import { formatTimeDuration, isSameAddress } from '@usecyan/core-kit';
import { Button, Select, Text, Title } from '@usecyan/ui-kit';

import { useCollectionPricer } from '@/queries/pricer/use-collection-pricer';
import { useSupportedCollections } from '@/queries/use-supported-collections';

import { CollectionNameAndDescription } from '../bnpl-collection/collection-details/collection-name-description';
import { CollectionStatsAndLinks } from '../bnpl-collection/collection-details/collection-stats-and-links';
import { LoanPricerChart } from './loan-pricer-chart';

export const LoanRatesPage = () => {
  const { bnplSupportedCollectionsParsed, isLoading: isCollectionLoading } = useSupportedCollections();
  const [selectedCollectionAddress, setSelectedCollectionAddress] = useState<Address | null>(null);
  const [showApr, setShowApr] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const { collectionPricer, isLoading } = useCollectionPricer(selectedCollectionAddress);

  const tableRef = useRef<HTMLTableElement>(null);

  const selectedCollection = useMemo(
    () =>
      selectedCollectionAddress
        ? bnplSupportedCollectionsParsed.find(collection =>
            isSameAddress(collection.address, selectedCollectionAddress)
          )
        : undefined,
    [selectedCollectionAddress, bnplSupportedCollectionsParsed]
  );

  const [hoveredCol, setHoveredCol] = useState<number | null>(null);

  const copyTable = () => {
    if (tableRef.current && selectedCollection) {
      const rows = tableRef.current.getElementsByTagName('tr');
      const maxLengths = Array.from(rows[0].children).map(() => 0);
      Array.from(rows, row => {
        Array.from(row.children).forEach((cell, index) => {
          maxLengths[index] = Math.max(maxLengths[index], cell.textContent?.length || 0);
        });
      });
      const spaceBetweenColumns = 8;
      const data =
        `${selectedCollection.name}\n\n` +
        Array.from(rows, row =>
          Array.from(row.children, (cell, index) =>
            (cell.textContent || '').padEnd(maxLengths[index] + spaceBetweenColumns, ' ')
          ).join('')
        ).join('\n');
      if ('clipboard' in navigator) {
        navigator.clipboard.writeText(data);
      } else {
        document.execCommand('copy', true, data);
      }
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (bnplSupportedCollectionsParsed.length > 0) {
      setSelectedCollectionAddress(bnplSupportedCollectionsParsed[0].address);
    }
  }, [bnplSupportedCollectionsParsed]);

  const chartData = useMemo(() => {
    if (!collectionPricer) return [];
    return collectionPricer.bnpl.durations.map(duration => ({
      duration: formatTimeDuration(duration, true),
      ...Object.fromEntries(
        collectionPricer.bnpl.ltvs.map(loanRate => [
          `${loanRate.toFixed(2)}%`,
          collectionPricer.bnpl.configs.has(`${loanRate}-${duration}`)
            ? showApr
              ? collectionPricer.bnpl.configs.get(`${loanRate}-${duration}`)!.planModal.apr
              : collectionPricer.bnpl.configs.get(`${loanRate}-${duration}`)!.planModal.interestRate
            : null,
        ])
      ),
    }));
  }, [collectionPricer, showApr]);

  return (
    <section className="flex w-full max-w-screen-lg flex-col gap-y-12 place-self-center pt-6 max-sm:gap-y-7">
      <Title size={5}>NFT loan interest rates by collection</Title>
      <div className="flex flex-col gap-y-8">
        <Select>
          <Select.Input
            value={selectedCollectionAddress ?? ''}
            onChange={value => setSelectedCollectionAddress(value === '' ? null : (value as Address))}
            options={bnplSupportedCollectionsParsed.map(collection => ({
              value: collection.address,
              label: collection.name,
            }))}
          />
        </Select>
        <div className="flex flex-col gap-y-8">
          <CollectionNameAndDescription collectionData={selectedCollection} />
          <CollectionStatsAndLinks collectionData={selectedCollection} isColumnPrioritized />
        </div>
      </div>
      {/*  ---------- TABLE ---------- */}
      <div className="flex flex-col items-center gap-y-24 max-sm:gap-y-8">
        <div className="flex w-full flex-col gap-y-12">
          <div className="flex w-full flex-row items-center justify-between">
            <Text size="lg-s" weight="semibold">
              NFT loan interest table (APR%)
            </Text>
            <div className="w-full max-w-48">
              <Select>
                <Select.Input
                  value={showApr ? 'APR' : 'IR'}
                  onChange={value => setShowApr(value === 'APR')}
                  options={[
                    { value: 'APR', label: 'APR (Ann. rates)' },
                    { value: 'IR', label: 'Interest Rate' },
                  ]}
                />
              </Select>
            </div>
          </div>

          <div className="flex w-full flex-col gap-y-5">
            <div className="flex flex-row items-center gap-x-2 place-self-center">
              <Text size="sm" weight="semibold">
                Duration of Loan
              </Text>
              <Button variant="outline" size="xs" onClick={copyTable} icon={isCopied ? <TbCheck /> : <TbCopy />}>
                <Text size="xxs" weight="semibold" color="cyan">
                  Copy table
                </Text>
              </Button>
            </div>
            <div className="relative flex w-full flex-row">
              <Text size="sm" weight="semibold" align="center" className="absolute left-0 top-1/2 -ml-8 -rotate-90">
                LTV
              </Text>
              <div className="flex w-full flex-col gap-y-5">
                {(isLoading || isCollectionLoading) && <div className="skeleton h-[200px] min-h-[200px] w-full" />}
                {collectionPricer && (
                  <table className="w-full cursor-pointer" ref={tableRef} onMouseLeave={() => setHoveredCol(null)}>
                    <colgroup>
                      <col />
                      {collectionPricer.bnpl.durations.map((_, index) => (
                        <col key={index} className={hoveredCol === index ? 'bg-black/5 dark:bg-white/5' : ''} />
                      ))}
                    </colgroup>
                    <thead className="border-border-color-secondary border-b">
                      <tr>
                        <th className="border-border-color-secondary border-r" />
                        {collectionPricer.bnpl.durations.map((duration, index) => (
                          <th key={index} className="pb-2 text-center">
                            <Text size="sm" weight="semibold">
                              {formatTimeDuration(duration, true)}
                            </Text>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {collectionPricer.bnpl.ltvs.map((loanRate, index) => (
                        <tr key={index} className="hover:bg-black/5 hover:dark:bg-white/5">
                          <td className="border-border-color-secondary border-r py-1.5 text-center">
                            <Text size="sm" align="center">
                              {loanRate}%
                            </Text>
                          </td>
                          {collectionPricer.bnpl.durations.map((duration, index) => (
                            <td
                              key={index}
                              className="text-center hover:bg-gray-500"
                              onMouseEnter={() => setHoveredCol(index)}
                            >
                              <Text size="sm" align="center">
                                {collectionPricer.bnpl.configs.has(`${loanRate}-${duration}`)
                                  ? showApr
                                    ? `${collectionPricer.bnpl.configs.get(`${loanRate}-${duration}`)!.planModal.apr.toFixed(2)}%`
                                    : `${collectionPricer.bnpl.configs
                                        .get(`${loanRate}-${duration}`)!
                                        .planModal.interestRate.toFixed(2)}%`
                                  : '-'}
                              </Text>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*  ---------- CHART ---------- */}
        <div className="flex w-full flex-col gap-y-12">
          <Text size="lg-s" weight="semibold">
            NFT loan interest curve ({showApr ? 'APR%' : 'Interest Rate%'})
          </Text>
          <div className="relative flex w-full flex-row">
            <Text size="sm" weight="semibold" align="center" className="absolute left-0 top-1/2 -ml-8 -rotate-90">
              {showApr ? 'APR' : 'IR'}
            </Text>
            <LoanPricerChart
              chartData={chartData}
              showApr={showApr}
              isLoading={isLoading || isCollectionLoading}
              ltvs={collectionPricer?.bnpl.ltvs ?? []}
            />
          </div>
          <Text size="sm" weight="semibold" align="center">
            Duration of Loan
          </Text>
        </div>
      </div>
    </section>
  );
};
